import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar';
import UserGrid from './UserGrid';
import Actions from '../components/Actions';
import Alert from 'sweetalert2'
import Button from "react-bootstrap/Button";
import { getUser, listUsers } from '../Services/UserService';
import { useReactOidc } from "@axa-fr/react-oidc-context";
import './SearchPage.css';

const SearchPage = (props) => {
  const { oidcUser } = useReactOidc();
  useEffect(() => {
    setUserList([]);
    setInput('');
    setSelectedUser('');
  }, [props.selectedUserPool]);

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [input, setInput] = useState('');
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasInputExceededTwoChars, setHasInputExceededTwoChars] = useState(false);

  const updateInput = async (input) => {
    setInput(input);
    console.log('input: ' + input);
    if(input.length === 0) {
      setUserList([]);
      setHasInputExceededTwoChars(false); // Reset the flag
      return;
    }
    
    if(input.length > 2) {
      setHasInputExceededTwoChars(true); // Set the flag to true
    }
  }

  const resetUser = () => {
    setSelectedUser('');
    setSelectedLanguage('');
    setSelectedEmail('');
  }
  const handleSearch = (input) => {
    setUserList([]);
    setSelectedUser('');
    setSelectedEmail('');
    if (input.length < 3) {
      setSearchTerm("Please enter at least 3 characters");
      Alert.fire({ text: "Please enter at least 3 characters", icon: 'error' });
      return;
    }else{
      setSearchTerm("Searching...");
      getUserList(input);
    }
  }
  const getUserList = async (input) => {
      const params = {
        UserPoolId: props.selectedUserPool.Id, /* required */
        //AttributesToGet: ["email", "locale"], not easy to pass these so hard coding in lambda
        FilterString: input,
        //Filter: 'username ^= \"' + input + '\"',
        //Limit: 60,
        //  PaginationToken: 'STRING_VALUE'
        access_token: oidcUser.access_token,
      };
      try {
        const data = await listUsers(params);
        if (data != null) {
          setUserList(data.data);
          if (data.LimitExceeded === true) {
            setSearchTerm("The search for '" + input + "' returned too many results.  Please refine your search.");
          }else{
            setSearchTerm("Search results for: " + input);
          }
        }
      } catch (error) {
        Alert.fire({ text: error, icon: 'error' })
      }
  }
  const onDelete = (user) => {
    // remove user from data
    var newUsers = [];
    for (var i = 0; i < userList.length; i++) {
      if (userList[i].Username !== user) {
        newUsers.push(userList[i]);
      }
    }
    setUserList(newUsers);
    resetUser();
  }
  const onAddUser = (user) => {
    var inp = input;
    getUserList(inp);
  }
  const onRefreshUser = async (user) => {
    const params = { UserPoolId: props.selectedUserPool.Id, Username: user, access_token: oidcUser.access_token };

    try {
      const data = await getUser(params)
      // update dataset   
      // need to add a new user, not just modify an existing user.  Even a clone wouldn't work.  Issue with react-bootstrap-table2 component
      var newUsers = [];
      for (var i = 0; i < userList.length; i++) {
        if (userList[i].Username !== user) {
          newUsers.push(userList[i]);
        }
        else {
          var newUser = { Username: user, UserStatus: data.UserStatus, Email: data.Email, Enabled: data.Enabled, Language: data.Language, Region: data.Region, MFAPref: data.MFAPref, LastModifiedBy: data.LastModifiedBy}
          //console.log('data: ',data);
          console.log('Refreshing user: ' + newUser.Username + ' in the UserList', userList);
          newUsers.push(newUser);
        }
      }
      setUserList(newUsers);
    } catch (error) {
      Alert.fire({ text: error, icon: 'error' })
    }
  }
  const onUserSelected = (user) => {
    setSelectedUser(user);
    console.log('user selected: ' + user);
  }
  const onLanguageSelected = (lang) => {
    setSelectedLanguage(lang);
    console.log('lang selected: ' + lang);
  }
  const onEmailSelected = (email) => {
    setSelectedEmail(email);
    console.log('email selected: ' + email);
  }

  return (
    <>
        <div className="search-form form-group">
          <div className="search-input">
            <SearchBar
              input={input}
              onChange={setInput}
              disabled={!props.selectedUserPool}
              onSubmit={()=>handleSearch(input)}
            />
          </div>
          <div className="search-action">
          <Button variant="primary" onClick={() => handleSearch(input)} disabled={!props.selectedUserPool}>
              Search
              <i class="bi bi-search"></i>
            </Button>
          </div>
        </div>
        {searchTerm &&
          <div>{searchTerm}</div>
        }
      <div>
        <UserGrid 
          users={userList} 
          selectedUser={onUserSelected} 
          selectedLanguage = {onLanguageSelected} 
          selectedEmail = {onEmailSelected} 
          selectedUserPool={props.selectedUserPool}
        />
      </div>
      <div>
        <Actions 
          user={selectedUser} 
          language = {selectedLanguage} 
          email = {selectedEmail} 
          selectedUserPool={props.selectedUserPool} 
          delete={onDelete} 
          refreshUser={onRefreshUser} 
          addUser={onAddUser} 
        />
      </div>
    </>
  );
}

export default SearchPage