import React from "react";
import { AuthenticationContext } from "@axa-fr/react-oidc-context";
import { Button, Navbar, Nav } from "react-bootstrap";
import logo from "../assets/logo2.svg";

function Header () {
  return (
  <>
    <AuthenticationContext.Consumer>
      {(props) => {
        return (
          <div>
            <Navbar bg="light" expand="lg">
              <Navbar.Brand href="home">
                <img
                  alt=""
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />{' '}
              User Pool Manager
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  {props.oidcUser || !props.isEnabled ? (
                    <>  
                      <div><Nav.Link href="home">Home</Nav.Link>  </div>
                      <div><Nav.Link href="banner">Banner</Nav.Link></div>   
                    </>
              
                    ) : (<div></div>

                  )}
                </Nav>
                {props.oidcUser || !props.isEnabled ? (
                  <Button onClick={props.logout}>logout</Button>) : (
                  <Button onClick={props.login} >login</Button>)}
              </Navbar.Collapse>
            </Navbar>
          </div>)
      }}
    </AuthenticationContext.Consumer>
  </>
  );
};
export default Header;