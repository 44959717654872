import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { withOidcSecure, OidcSecure } from '@axa-fr/react-oidc-context';
import Home from '../Pages/Home';
import Dashboard from '../Pages/Dashboard';
import Admin from '../Pages/UserPools';
import Banner from '../Pages/Banner';

const notFoundStyle = {
  padding: 10
};
const PageNotFound = () => <p style={notFoundStyle}>404 Page Not Found</p>


withOidcSecure(Home);

const Routes = () => (
  <Switch>
 <Route exact path="/" component={Dashboard} />
 
    {/* //note the admin screen link is hidden in the header.js file but is still accessible if you know the url
        //TODO revamp the screen since maintaining user pools is all it does and that is not a common task and should be done in the AWS console */}
    {<Route path="/admin">
      <OidcSecure>
        <Admin />
      </OidcSecure>
    </Route>}

    {<Route path="/banner">
      <OidcSecure>
        <Banner />
      </OidcSecure>
    </Route>}
    {<Route path="/home">
      <OidcSecure>
        <Home />
      </OidcSecure>
    </Route>}
    <Route component={PageNotFound} />
  </Switch>
);

export default  withRouter(Routes);
