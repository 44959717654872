//import React, { useState, useEffect } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

function UserGrid(props) {
    const columns = [
        { dataField: 'Username', text: 'User Name',
            title: true,
            headerStyle: { width: '230px' },
            style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}
        },
        { dataField: 'Email', text: 'Email',
            title: true,
            headerStyle: { width: '230px' },
            style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}
        },
        { dataField: 'UserStatus', text: 'User Status', searchable: false,
            title: true,
            headerStyle: { width: '120px' },
            style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}
        },
        { dataField: 'Enabled', text: 'Enabled', searchable: false,
            headerStyle: { width: '90px' },
            style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}
        },
        { dataField: 'Language', text: 'Language',
            headerStyle: { width: '120px' },
            style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}
        },
        { dataField: 'Region', text: 'Region',
            headerStyle: { width: '80px' },
            style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}
        },
        { dataField: 'MFAPref', text: 'MFA Preference',
            headerStyle: { width: '120px' },
            style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}
        },
        { dataField: 'LastModifiedBy', text: 'Last Modified By',
            title: true,
            headerStyle: { width: '230px' },
            style: {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}
        },
        // {dataField: 'CreatedOn',  text: 'Created On'}, 
        // {dataField: 'UserLasModifiedDate',  text: 'Modified On'}
    ];

    const selectRow = {
        hideSelectColumn: true,
        clickToSelect: true,
        mode: 'radio',
        bgColor: '#6c757d',
        onSelect: (row, isSelect, rowIndex, e) => {
            //  alert('selected: ' + row.Username + ", " + row.Email);
            props.selectedUser(row.Username);
            props.selectedLanguage(row.Language);
            props.selectedEmail(row.Email);
        }
    };
    

    return (
        <>
            <BootstrapTable
                keyField="Username"
                data={props.users}
                columns={columns}
                striped
                hover
                noDataIndication="no users found"
                selectRow={selectRow}
                condensed
            />
        </>
    )
}

export default UserGrid;
