import httpCommon from '../Services/httpService';

export const addUser = async(data,params)=> {
    return httpCommon(params.access_token).post('/user/adduser', data)
}

export const listUsers = async (params) => {
    const response = await httpCommon(params.access_token).get(`/pool/getusers?userpoolid=${params.UserPoolId}&filterstring=${params.FilterString}`);
    if (response.data.data != null) {
        return response.data;
    }
}

export const getUser = async (params) => {
    const response = await httpCommon(params.access_token).get(`/pool/getuser?userpoolid=${params.UserPoolId}&username=${params.Username}`);
    if (response.data.data != null) {
        return response.data.data;
    }
}

export const setUserPassword = async (data, params) => { 
    const response = await httpCommon(params.access_token).post('/user/resetuser', data);
    return response.data;
}

export const deleteUser = async (data,params) => { 
    const response = await httpCommon(params.access_token).post('/user/deleteuser', data);
    return response.data;
}

export const updateRegion = async (data,params) => { 
    const response = await httpCommon(params.access_token).post('/user/region', data);
    return response.data;
}
