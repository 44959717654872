//see env-cmd on how environment variables used in this file are configured
const configuration = {
  env_label: `${process.env.REACT_APP_ENV_LABEL}`,

  //AuthenticationProvider oidcConfiguration settings
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,   // 'userpoolmanager',
  redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}`, // 'https://upm.internal:3000/authentication/callback',
  response_type:  `${process.env.REACT_APP_RESPONSE_TYPE}` ,// 'token',
  post_logout_redirect_uri: `${process.env.REACT_APP_POST_LOGOUT_URL}`, //'https://upm.internal:3000',
  scope: `${process.env.REACT_APP_SCOPE}`,  // 'openid profile',
  authority: `${process.env.REACT_APP_AUTHORITY}`,  // 'https://auth.kochid.com',
  silent_redirect_uri: `${process.env.REACT_APP_SILENT_REDIRECT_URL}`, //'https://upm.internal:3000/authentication/silent_callback',
  automaticSilentRenew: true,
  loadUserInfo: false,

  notifications_config: {
    url: `${process.env.REACT_APP_NOTIFICATIONS_URL}`, //NOTE this must not have the ending slash
    apikey: `${process.env.REACT_APP_NOTIFICATIONS_APIKEY}`, 
  },
};

export default configuration;