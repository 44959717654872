import React from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import UserPoolGrid from '../components/UserPoolGrid';

function UserPools() {
 
    return (
        <>    
        <Container>
        <Row><p></p> </Row>
         
          <Row >
            <div>
               <UserPoolGrid />
                </div>
          </Row>
        </Container>
      

    </>
    );
  }
  
  export default UserPools;