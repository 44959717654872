import React, { useState, useEffect ,useCallback} from 'react';
import { Form } from "react-bootstrap";
import Alert from 'sweetalert2'
import { scanUserPools } from '../Services/UserPoolService'
import { useReactOidc } from "@axa-fr/react-oidc-context";

const UserPoolList = (props) => {
  const { oidcUser } = useReactOidc();
  const [userPools, setUserPools] = useState([]);
const getUserPools =  useCallback(() =>  {
    scanUserPools({access_token: oidcUser.access_token,}).then(arrayOfData=>{
      arrayOfData.unshift({ Id: 0, Description: "Select One", NotificationsApp: "", DefaultMFA: ""});
      setUserPools(arrayOfData);
      props.isUserPoolListLoaded(false);
  
    }).catch(error=>{
      console.log(error);
      Alert.fire({ text: 'Unable to scan the table. Error JSON:' + JSON.stringify(error, null, 2), icon: 'error' })
    });
},[oidcUser,props]);

  useEffect(() => {
    console.log('useeffect');
    // read database to load user pools
    getUserPools()
  }, [getUserPools]);


  const handleChange = (e) => {
    for (var i = 0; i < userPools.length; i++) {
      if (userPools[i].Id === e.target.value) {
        props.userPool(userPools[i]);
        break;
      }
    }
  };

  const renderSelect = () => {
    return userPools.map((pool, index) => {
      //const { Id, Description, NotificationsApp } = pool //destructuring
      const { Id, Description, NotificationsApp, DefaultMFA } = pool //destructuring
      const dataTag = {NotificationsApp: NotificationsApp, DefaultMFA: DefaultMFA}
      return (
        <option key={Id} value={Id} data-tag={dataTag}>{Description}</option>
      )
    })
  }

  return (
    <>
      <p></p>
      <div>
    
        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
          <Form.Label>Select a User Pool</Form.Label>
          <Form.Control
            as="select"
            //    value={selectedUserPool || ''}   // doesn't work
            onChange={handleChange}>
            {renderSelect()}
          </Form.Control>         
        </Form.Group>
        
      </div>
      
    </>
  )
};

export default UserPoolList;
