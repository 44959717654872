import "./BannerSelect.css";
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import DatePicker from 'react-datepicker';
import httpCommon from "../Services/httpService";
import {useReactOidc} from "@axa-fr/react-oidc-context";
import Badge from 'react-bootstrap/Badge';

const BannerSelect = ({ bannerDetails, poolId }) => {
    const { oidcUser } = useReactOidc();
    let bannerPlanned = [];
    let bannerUnplanned = [];

    console.log("Rendering banner configurations...", bannerDetails);

    if(bannerDetails !== undefined && Array.isArray(bannerDetails)){
        bannerPlanned = bannerDetails.filter(banner => banner.BannerType === "planned");
        bannerUnplanned = bannerDetails.filter(banner => banner.BannerType === "unplanned");
        console.log("Found a planned banner: ", bannerPlanned);
        console.log("Found an unplanned banner: ", bannerUnplanned);
    } else {
        // Show an error message
    }

    const handleBannerItemRecordSaved = (data) => {
        console.log("handleBannerItemRecordSaved: ", data);
        bannerPlanned[0] = data;
    }
    const handleBannerUnplannedRecordSaved = (data) => {
        console.log("handleBannerUnplannedRecordSaved: ", data);
        bannerUnplanned[0] = data;
        if(data.IsActive){
            bannerPlanned[0].InEffect = false;
        }
    }

    return(
        <div className="banner-details alert alert-dark" role="alert">
            <p>The following banners are defined for this user pool. Please note that items highlighted in yellow are currently IN EFFECT.</p>
            <div className="banner-section">
                <div className="banner-title">
                    <h5>Outage Banners</h5>
                </div>
                <div className="banner-list">
                    <BannerUnplanned data={bannerUnplanned[0]} oidcUser={oidcUser} poolId={poolId} onRecordSaved={handleBannerUnplannedRecordSaved}/>
                    {bannerPlanned.length > 0 ? <BannerItem data={bannerPlanned[0]} oidcUser={oidcUser} poolId={poolId} onRecordSaved={handleBannerItemRecordSaved}/> : <div className="banner-item">No Planned Banners</div>}
                </div><p></p>
                <div>
                    <div className="banner-title">
                        <h5>Legend</h5>
                    </div>
                    <div className="flexContainer">
                        <Badge variant="warning">In Effect</Badge>
                        <div>Indicates that the banner is currently being displayed to all users!</div>
                    </div>
                    <div className="flexContainer">
                        <Badge variant="secondary">Disabled</Badge>
                        <div>Banner is expired or the Start Date is in the future.</div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

const BannerItem = ({ data, oidcUser, onRecordSaved }) => {
    const [bannerDetails, setBannerDetails] = useState(data);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [notificationLeadDays, setNotificationLeadDays] = useState(data.NotificationLeadDays);
    const [startDate, setStartDate] = useState(new Date(data.StartDateUTC));
    const [endDate, setEndDate] = useState(new Date(data.EndDateUTC));
    const [regions, setRegions] = useState(data.Regions);

    console.log("Rendering BannerItem...", data);

    const handleSetStartDate = (date) => {
        //data.StartDateUTC = date.toISOString();
        //handle if change startdate to be after end date
        if(date > endDate){
            setEndDate(date);
        }
        setStartDate(date);
    }
    const handleSetEndDate = (date) => {
        //data.EndDateUTC = date.toISOString();
        setEndDate(date);
    }
    const handleNotificationLeadDays = (days) => {
        //data.NotificationLeadDays = days;
        setNotificationLeadDays(days);
    }
    const openModal = () => {
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }
    const handleSave = async () => {

        const bannerChanges = {...bannerDetails,
            StartDateUTC: startDate.toISOString(),
            EndDateUTC: endDate.toISOString(),
            NotificationLeadDays: notificationLeadDays,
            IsActive: true, //if we edit and make changes we assume it is active
            Regions: regions.includes("all") ? ["all"] : regions
        }

        console.log("Saving banner...", bannerChanges);

        let postSaveRecord;
        try {
            const response = await httpCommon(oidcUser.access_token).post(`/pool/banner`, bannerChanges);
            postSaveRecord = response.data;  //api returns the new banner object
            onRecordSaved(response.data); //notify parent that a record was saved
            setRegions(bannerChanges.Regions);
            setBannerDetails(postSaveRecord);  //shouldn't this be up in the success and set the data = response?
            closeModal();
        } catch (e) {
            alert(`Save failed. Please retry your changes. If the error persists, please contact the admin.`);
            console.error(e,postSaveRecord);
        } finally {
            
        }
    }

    const handleCancel = async () => {
        console.log("Reverting banner changes...");

        setStartDate(new Date(bannerDetails.StartDateUTC));
        setEndDate(new Date(bannerDetails.EndDateUTC));
        setNotificationLeadDays(bannerDetails.NotificationLeadDays);
        setRegions(bannerDetails.Regions);
        
        closeModal();
    }

    const convertUTCDateStringToLocalString = (utcDateString) => {
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            timezone: "UTC",
            hour: "numeric",
            minute: "numeric",
            //second: "numeric"
        };
        let utcDate = new Date(utcDateString);
        return utcDate.toLocaleString('en-us', options);
    }

    return(
        <div className={ bannerDetails.InEffect ? "banner-item banner-ineffect" : "banner-item banner-inactive"}>
            <div className="banner-item-title">
                Planned banner
            </div>
            <div className="banner-item-content">
                <div className="item-status sm">Start Date: {convertUTCDateStringToLocalString(bannerDetails.StartDateUTC)}</div>
                <div className="item-status sm">End Date: {convertUTCDateStringToLocalString(bannerDetails.EndDateUTC)}</div>
            </div>
            <div className="banner-item-actions">
                <button className="btn btn-sm btn-outline-secondary btn-primary" onClick={openModal}>Edit</button>
            </div>
            <Modal
                show={modalIsOpen}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                centered
                contentLabel="Edit Planned Banner"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Planned Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="startDate">Start Date (local)</label><br />
                                <DatePicker
                                    selectsStart
                                    showTimeInput
                                    selected={startDate}
                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(date) => handleSetStartDate(date)}
                                    shouldCloseOnSelect={false}
                                >
                                </DatePicker>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="endDate">End Date (local)</label><br />
                                <DatePicker
                                    selectsEnd
                                    showTimeInput
                                    selected={endDate}
                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    onChange={(date) => handleSetEndDate(date)}
                                >
                                </DatePicker>
                            </div>
                        </div>
                        <div className="form-row">
                                <label htmlFor="leadTime">Lead time: (How many days to show the banner before the outage)</label>
                                <input type="text"
                                    id="leadTime"
                                    value={notificationLeadDays}
                                    onChange={(e) => handleNotificationLeadDays(e.target.value)}
                                />
                        </div><br/>
                        <div className="form-row">
                            <div className="form-group">
                            <label htmlFor="leadTime">Choose which regions should see the banner:</label><br/>
                                <div className="col-md-6">
                                    <select class="form-select select-region" 
                                        size="7" 
                                        id="regions" 
                                        value={regions}
                                        multiple aria-label="Region Select"
                                        onChange={(e) => setRegions(Array.from(e.target.selectedOptions, option => option.value))}
                                    >
                                        <option value="all">All regions</option>
                                        <option value="AMEIA">Africa-ME</option>
                                        <option value="AP">Asia-Pacific</option>
                                        <option value="EU">Europe</option>
                                        <option value="LA">Latin American</option>
                                        <option value="NA">North America</option>
                                        <option value="RU">Russia/CIS</option>
                                    </select>
                                </div>
                                <p><em>You can hold shift to select multiple regions</em></p>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-outline-secondary btn-danger" onClick={handleCancel}>Cancel</button>
                    <button className="btn btn-sm btn-outline-secondary btn-primary" onClick={handleSave}>Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
const BannerUnplanned = ({ data, oidcUser, pool: poolId, onRecordSaved }) =>{
    const [bannerDetails, setBannerDetails] = useState(data);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => { setModalIsOpen(true); }
    const closeModal = () => {setModalIsOpen(false);}

    console.log("Rendering BannerUnplanned...", data);

    if(data === undefined){
        let newBanner = {
            "ActivatedDateUTC": "2023-11-20T19:07:00.0213388Z",
            "InactivatedDateUTC": "",
            "UserPoolId": poolId,
            "BannerId": "",
            "BannerType": "unplanned",
            "IsActive": true,
            "InEffect": false,
            "AllowLogin": false,
            "OutageBannerMessageKey": "UnplannedOutageBannerMessageLoginNotAllowed"
        };
        const handleAdd = async () => {
            console.log("Saving Banner: ", JSON.stringify(data));
            openModal();
            try {
                const fetchResponse = await httpCommon(oidcUser.access_token).post(`/pool/banner`, newBanner);
                const response = await fetchResponse.json();
                return response;
            } catch (e) {
                return e;
            } finally {
                closeModal();
            }
        }
        return(
            <div className="banner-item">
                No Unplanned Banners
                <button className="btn btn-sm btn-outline-secondary btn-success" onClick={handleAdd}>Add New</button>
                <Modal
                    show={modalIsOpen}
                    onHide={closeModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                    contentLabel="Edit Banner"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add an Unplanned Banner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>This will create a new banner for unplanned outages. Are you sure?</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-sm btn-outline-secondary btn-danger" onClick={closeModal}>Cancel</button>
                        <button className="btn btn-sm btn-outline-secondary btn-primary" onClick={handleAdd}>Add</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    } else {
        const act = bannerDetails.IsActive;

        const handleDeactivate = async () => {
            await handleSave();
        }
        
        const handleSave = async () => {
            console.log("Saving Banner: ", data);

            const bannerChanges = {...bannerDetails,
                IsActive: !bannerDetails.IsActive, //toggle the IsActive flag
            }
    
            console.log(bannerChanges);

            let postSavedRecord;
            try {
                const response = await httpCommon(oidcUser.access_token).post(`/pool/banner`, bannerChanges);
                console.log("Unplanned Banner Save operation complete", response.data);
                postSavedRecord = response.data;  //api returns the new banner object
                onRecordSaved(postSavedRecord); //notify parent that a record was saved
                setBannerDetails(postSavedRecord);  //shouldn't this be up in the success and set the data = response?
                closeModal();
            } catch (e) {
                alert(`Save failed. Please retry your changes. If the error persists, please contact the admin.`);
                console.error(e);
            } finally {
                
            }
        }

        return(
            <div className={ bannerDetails.InEffect ? "banner-item banner-ineffect" : "banner-item banner-inactive"}>
                <div className="banner-item-title">
                    Unplanned Banner
                </div>
                <div className="banner-item-content">
                    Status : {act ? "In Effect" : "Disabled"}
                </div>
                <div className="banner-item-actions">
                    {act ? 
                    <button className="btn btn-sm btn-outline-secondary btn-danger" onClick={handleDeactivate}>Deactivate</button>
                    :
                    <button className="btn btn-sm btn-outline-secondary btn-primary" onClick={openModal}>Activate</button>
                    }
                </div>
                <Modal
                    show={modalIsOpen}
                    onHide={closeModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                    contentLabel="Edit Banner"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Activate Unplanned Banner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>This will activate the unplanned outage banner, which will display to all users on the login page.  This will prevent users from accessing the system while in effect. <br/><br/> Are you sure you want to proceed?</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-sm btn-outline-secondary btn-danger" onClick={closeModal}>Cancel</button>
                        <button className="btn btn-sm btn-outline-secondary btn-primary" onClick={handleSave}>Save</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default BannerSelect;