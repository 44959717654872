import httpCommon from '../Services/httpService';
//import { useReactOidc } from "@axa-fr/react-oidc-context";
export const scanUserPools = async (params) => {
  //  const { oidcUser } = useReactOidc();
   // console.log(oidcUser);
        
    const response = await httpCommon(params.access_token).get('/pool/list');
    if (response.data.data != null) {
        return response.data.data;
    }
}

export const putUserPool = async (data,params) => {
    //const { oidcUser } = useReactOidc();
    //console.log(oidcUser);
    
    const response = await httpCommon(params.access_token).post('/pool/putpool', data);

    return response.data;
}
