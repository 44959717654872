import axios from "axios";



const httpService = (access_token)=>{ 
    return axios.create(
        {
            baseURL: `${process.env.REACT_APP_UPMAPIURL}`,
            headers:{"Authorization":"Bearer "+access_token},
        }
    );
};

export default httpService;