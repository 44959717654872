import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthenticationProvider, oidcLog, InMemoryWebStorage } from '@axa-fr/react-oidc-context';
import Header from './Layout/Header';
import Routes from './Router/Routes';
import oidcConfiguration from './configuration'; //see env-cmd on how environment variables used in this file are configured
import './App.css';

console.log({ version: process.env.REACT_APP_VERSION})
console.log({ NODE_ENV: process.env.NODE_ENV} );
let env_label = process.env.REACT_APP_ENV_LABEL

console.log(env_label );
if(env_label){
  console.log({ config_env: process.env.REACT_APP_ENV_LABEL });
  //TODO possible secrets bleed to log; remove this >> ({ oidcConfiguration: oidcConfiguration} );
  console.log({ notifications_url: process.env.REACT_APP_NOTIFICATIONS_URL} );
}
else{
  console.error("ERROR: environment variables not properly configured.")
}

const App = () => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `User Pool Manager ${process.env.REACT_APP_VERSION}`;
  });
  return (
    <div> 
      <AuthenticationProvider
        configuration={oidcConfiguration}
        loggerLevel={oidcLog.DEBUG}
        isEnabled={true}
        notAuthenticated={NotAuthenticatedPlaceholder}
        notAuthorized={NotAuthorizedPlaceholder}
        callbackComponentOverride={CallbackPlaceholder}
        UserStore={InMemoryWebStorage}    >
        <Router>
          <Header />
          <Routes />
        </Router>
      </AuthenticationProvider> 
    </div>
  )};

const Msg = ({ msg }) => {
  return (
    <div>
      <br />
      <span>{msg}</span>
    </div>
  );
}

const NotAuthenticatedPlaceholder = () => <Msg msg={`Not Authenticated`} />;
const NotAuthorizedPlaceholder = () => <Msg msg={`Not Authorized`} />;
const CallbackPlaceholder = () => <Msg msg={`Signed out`} />;

export default App;
