import React, { useState } from "react";
import UserPoolList from '../components/UserPoolList';
import SearchPage from '../components/SearchPage';
import {  Row } from "react-bootstrap";

function Home() { 
  const [selectedUserPool, setSelectedUserPool] = useState('');
  const [isUserPoolListLoaded, setIsUserPoolListLoaded] = useState(true);

  const onUserPoolSelected = (pool) => {
    console.log('user pool ' + pool.Id + ' selected');
    setSelectedUserPool(pool);
  }

  const onUserPoolLoaded = (isloaded) =>{
    console.log('User Pool List Loaded');
    setIsUserPoolListLoaded(isloaded);
  }

  return (
    <>    
        <div style={{ margin: '50px' }}>
          <Row>
                <p></p> 
          </Row>
          <Row>
              <UserPoolList userPool={onUserPoolSelected} isUserPoolListLoaded={onUserPoolLoaded} />
          </Row>       
          <Row >
              <div>
                <SearchPage selectedUserPool={selectedUserPool} />
              </div>
          </Row>
          {
            isUserPoolListLoaded ?    
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div> 
            : null
          }            
        </div>
    </>
  );
}

export default Home;