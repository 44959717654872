import Form from "react-bootstrap/Form";
import React from 'react';

const SearchBar = ({ input, onChange, disabled, onSubmit }) => {
  return (
    <>
      <Form.Control
        key="random1"
        value={input}
        placeholder={"Search user pool"}
        onChange={(e) => onChange(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onSubmit();
          }
        }}
        disabled={disabled}
      />
    </>
  );
}

export default SearchBar