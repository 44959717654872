import httpCommon from '../Services/httpService';


export const listGroups = async (params) => {
    const response = await httpCommon(params.access_token).get(`/pool/group/listgroups?userpoolid=${params.UserPoolId}`);
    
    if (response.data.data != null) {
        return response.data.data;
    }
}

export const addUserToGroup = async (data,params) => {
    const response = await httpCommon(data.access_token).post('/pool/group/adduser', data);
    
    if (response.data.data != null) {
        return response.data.data;
    }
}

export const removeUserFromGroup = async (data,params) => {
    const response = await httpCommon(data.access_token).post('/pool/group/removeuser', data);
    
    if (response.data.data != null) {
        return response.data.data;
    }
}

export const listGroupsForUser = async (params) => {
    const response = await httpCommon(params.access_token).get(`/pool/group/listusergroups?userpoolid=${params.UserPoolId}&username=${params.Username}`);
    
    if (response.data.data != null) {
        return response.data.data;
    }
}


export const disableMFAForUser = async (params) => {
    const response = await httpCommon(params.access_token).get(`/pool/group/disablemfa?userpoolid=${params.UserPoolId}&username=${params.Username}`);
    
    if (response.data.data != null) {
        return response.data.data;
    }
}