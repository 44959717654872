import React from "react";
import Button from "react-bootstrap/Button";
import Alert from 'sweetalert2'
import { listGroups, addUserToGroup, removeUserFromGroup, listGroupsForUser,disableMFAForUser } from '../Services/GroupService'
import { setUserPassword, addUser, deleteUser, updateRegion } from '../Services/UserService'
import { useReactOidc } from "@axa-fr/react-oidc-context";
import jwt from "jwt-decode";

function Actions(props) {

  const { oidcUser } = useReactOidc();
  let decoded = jwt(oidcUser.access_token);
  //let session = jwt(oidcUser.session);
  //let groups = decoded.getGroups();
  let loggedInUser = decoded.username;
  const regionList =["AP","EU","LA","NA","RU"];

  const onCreateUser = async () => {

    await Alert.fire({
      title: 'Create user',
      html: GetHtml(),
      focusConfirm: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      preConfirm: async () => {
        //note in preConfirm must return true or return false after Alert.showValidationMessage

        let username = document.getElementById('create_userName').value;
        let password = document.getElementById('create_TempPassword').value;
        let phone_number = document.getElementById('create_phoneNumber').value;
        let email = document.getElementById('create_email').value;
        let region = document.getElementById('create_region').value;
        
        //not required
        let language = "";
        let mfa = "";
        if (document.getElementById('create_languagePref')){
          language = document.getElementById('create_languagePref').value;
        }
        if (document.getElementById('create_mfaPref')){
          mfa = document.getElementById('create_mfaPref').value;
        }

        if (!username) {
          Alert.showValidationMessage('A user name is required')
          return false;
        }
        if (!password) {
          Alert.showValidationMessage('A temporary password is required')
          return false;
        }
        if (!region) {
          Alert.showValidationMessage('You must assign a region to the user')
          return false;
        }
        if (props.selectedUserPool.NotificationsApp && !email) {
          Alert.showValidationMessage('Email is required (see Notification Options for selected user pool)')
          return false;
        }
        if (props.selectedUserPool.NotificationsApp && !language) {
          Alert.showValidationMessage('Language is required (see Notification Options for selected user pool)')
          return false;
        }
        //TODO: see if we can use a separate flag for MFA such as OfferMfa = T/F
        if (props.selectedUserPool.DefaultMFA && !mfa) {
          Alert.showValidationMessage('MFA is required (see MFA Options for selected user pool)')
          return false;
        }

        if (props.selectedUserPool.DefaultMFA && mfa === "phone" && !phone_number) {
          Alert.showValidationMessage('Phone number is required when selecting MFA = "Phone"')
          return false;
        }

        let params = {
          "MessageAction": "SUPPRESS",
          "TemporaryPassword": password,
          "Username": username,
          "UserPoolId": props.selectedUserPool.Id,
          "Email": email,
          "Locale": language,
          "PhoneNumber": phone_number,
          "MFAPreference": mfa,
          "LastModifiedBy": loggedInUser,
          "Region": region
        }
        console.log(params);
        try {

          const response = await addUser(params,{access_token: oidcUser.access_token,});

          props.addUser(response.data.User);

          // calls to Notification Services have been moved to the lambda supporting the action (above)
          // //NOTE this is fire and forget
          // await notifyAddUser(props.selectedUserPool.NotificationsApp,
          //   {
          //     "Data": {
          //       "language": language,
          //       "userid": username,
          //       "email": email,
          //       "temppwd": password
          //     }
          //   }); //NOTE this is fire and forget

          Alert.fire({
            text: 'User ' + response.data.User.Username + ' created successfully',
            icon: 'success'
          })

          return true;

        } catch (error) {
          Alert.showValidationMessage('Action failed: ' + error.response.data.Error)
          return false;
        }
      }
    })
  }

  const phoneToolTip = "Please use phone number format '+' country code and phone number (no spaces or additional characters required). e.g. +441234567890";

  const GetHtml = () => {
    var html = '<div class="form-group" >' +
      '<label for="create_userName">Username *</label>' +
      '<div class="col-sm"><input type="text" class="form-control" id="create_userName"/></div>' +
      '</div>' 

      html += '<div class="form-group" >' +
      '<label for="create_TempPassword">Temporary password *</label>' +
      '<div class="col-sm"><input type="text" class="form-control" id="create_TempPassword" placeholder="Temp1234!"/></div>' +
      '</div>' 

      if (props.selectedUserPool.DefaultMFA && props.selectedUserPool.DefaultMFA === "phone"){
        html += '<div class="form-group" >' +
        '<label for="create_phoneNumber">Phone Number *&nbsp;</label>' +
        '<i title="' + phoneToolTip + '" class="bi bi-question-circle-fill"></i>' +
        '<div class="col-sm"><input type="text" class="form-control" id="create_phoneNumber" placeholder="+18002451234"/></div>' +
        '</div>'
      }
      else {
        html += '<div class="form-group" >' +
        '<label for="create_phoneNumber">Phone Number&nbsp;</label>' +
        '<i title="' + phoneToolTip + '" class="bi bi-question-circle-fill"></i>' +
        '<div class="col-sm"><input type="text" class="form-control" id="create_phoneNumber" placeholder="+18002451234"/></div>' +
        '</div>'
      }

      html += '<div class="form-group" >' +
      '<label for="create_email">Email *</label>' +
      '<div class="col-sm"><input type="text" class="form-control" id="create_email"/></div>' +
      '</div>';

      console.log(props.selectedUserPool);

      html += 
      '<div class="form-group"><div class="col-6" style="display: inline-block;">' +
      '<label for="create_regions">Region *</label><br/>' +
      '<select class="form-select" id="create_region">' +
      '<option disabled selected value>Select a region...</option>' +
      '<option value="AMEIA">Africa-ME</option>' +
      '<option value="AP">Asia-Pacific</option>' +
      '<option value="EU">Europe</option>' +
      '<option value="LA">Latin American</option>' +
      '<option value="NA">North America</option>' +
      '<option value="RU">Russia/CIS</option>' +
      '</select></div>';

    if (props.selectedUserPool.NotificationsApp) {
      html += '<div class="col-6" style="display: inline-block;">' +
      '<label for="create_languagePref">Language *: </label><br/>' +
      '<select id="create_languagePref">' +
      ' <option value="">Select one...</option>' +
      ' <option value="chinese">Chinese Simplified</option>' +
      ' <option value="english">English</option>' +
      ' <option value="french">French</option>' +
      ' <option value="german">German</option>' +
      ' <option value="hungarian">Hungarian</option>' +
      ' <option value="italian">Italian</option>' +
      ' <option value="korean">Korean</option>' +
      ' <option value="polish">Polish</option>' +
      ' <option value="portuguese">Portuguese (BR)</option>' +
      ' <option value="russian">Russian</option>' +
      ' <option value="spanish">Spanish</option>' +
      ' <option value="thai">Thai</option>' +
      ' <option value="turkish">Turkish</option>' +
      ' <option value="portuguese_eu">Portuguese (EU)</option>' +
      ' <option value="vietnamese">Vietnamese</option>' +
      '</select></div></div>';

    }

    return html;
  }

  const onResetUser = async () => {

    let inputValue = "Temp1234!";

    const { value: temporaryPassword } = await Alert.fire({
      title: 'Reset password',
      input: 'text',
      inputLabel: 'Enter a temporary password:',
      inputValue: inputValue,
      inputPlaceholder: 'Temp1234!',
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter a temporary password'
        }
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    })

    if (temporaryPassword) {

      let params = {
        "TemporaryPassword": temporaryPassword,
        "Username": props.user,
        "UserPoolId": props.selectedUserPool.Id,
        "LastModifiedBy": loggedInUser,
        "Email": props.email,
        "Locale": props.language
      }

      try {

        const data = await setUserPassword(params,{access_token: oidcUser.access_token,});

        if (data.data != null) {
          props.refreshUser(props.user);
          Alert.fire({
            text: 'User password reset to ' + temporaryPassword,
            icon: 'success'
          })

          // calls to Notification Services have been moved to the lambda supporting the action (above)
          // //NOTE this is fire and forget
          // await notifyResetUser(props.selectedUserPool.NotificationsApp,
          //   {
          //     "Data": {
          //       "language": props.language,
          //       "userid": props.user,
          //       "email": props.email,
          //       "temppwd": temporaryPassword
          //     }
          //   }); //NOTE this is fire and forget

        }
      } catch (error) {
        Alert.fire({ text: error, icon: 'error' })
      }
    }
  }
  const onEditRegion = async () => {
    let params = {
      "UserPoolId": props.selectedUserPool.Id , /* required */
      access_token: oidcUser.access_token,
    }

    try {
      
      const { value: selectedIdx } = await Alert.fire({
        title: 'Edit region for ' + props.user,
        input: 'select',
        inputOptions: regionList.map(region => region),
        showCancelButton: true,
      })

      if (selectedIdx) {
        var params2 = {
          Region: regionList[selectedIdx], /* required */
          UserPoolId: props.selectedUserPool.Id, /* required */
          Username: props.user, /* required */
          access_token: oidcUser.access_token,
          LastModifiedBy: loggedInUser,
        }
        try {
          //TODO: Need backend function for: await UpdateUserRegion(params2);
          const response = await updateRegion(params2,{access_token: oidcUser.access_token,});
        }
        catch (error) {
          Alert.fire({ text: error, icon: 'error' })
        }
        Alert.fire({
          text: 'Updated region to ' + regionList[selectedIdx],
          icon: 'success'
        });
        props.refreshUser(props.user);
      }
    } catch (error) {
      Alert.fire({ text: error, icon: 'error' })
    }
  }
  const onAddGroup = async () => {
    let params = {
      //"Limit": 60,  //cannot limit unless we page
      //  "NextToken": "",
      "UserPoolId": props.selectedUserPool.Id , /* required */
      access_token: oidcUser.access_token,
    }

    try {
      const groupList = await listGroups(params);

      const { value: selectedIdx } = await Alert.fire({
        title: 'Add ' + props.user + " to group",
        input: 'select',
        inputOptions: groupList.map(group => group.GroupName),
        showCancelButton: true,
      })

      if (selectedIdx) {
        var params2 = {
          GroupName: groupList[selectedIdx].GroupName, /* required */
          UserPoolId: props.selectedUserPool.Id, /* required */
          Username: props.user, /* required */
          access_token: oidcUser.access_token,
        }
        try {
          await addUserToGroup(params2);
        }
        catch (error) {
          Alert.fire({ text: error, icon: 'error' })
        }
        Alert.fire({
          text: 'User added to group ' + groupList[selectedIdx].GroupName,
          icon: 'success'
        })
      }
    } catch (error) {
      Alert.fire({ text: error, icon: 'error' })
    }
  }
  const onRemoveGroup = async () => {

    try {
      const params = { UserPoolId: props.selectedUserPool.Id, Username: props.user ,access_token: oidcUser.access_token,};
      const groupList = await listGroupsForUser(params)

      const { value: selectedIdx } = await Alert.fire({
        title: 'Remove ' + props.user + " from group",
        input: 'select',
        inputOptions: groupList.map(group => group.GroupName),
        showCancelButton: true,
      })

      if (selectedIdx) {
        var params2 = {
          GroupName: groupList[selectedIdx].GroupName, /* required */
          UserPoolId: props.selectedUserPool.Id, /* required */
          Username: props.user, /* required */
          access_token: oidcUser.access_token,
        }
        try {
          await removeUserFromGroup(params2);
        }
        catch (error) {
          Alert.fire({ text: error, icon: 'error' })
        }
        Alert.fire({
          text: 'User removed from group ' + groupList[selectedIdx].GroupName,
          icon: 'success'
        })
      }
    } catch (error) {
      Alert.fire({ text: error, icon: 'error' })
    }
  }
  const onShowGroups = async () => {

    try {
      const params = { UserPoolId: props.selectedUserPool.Id, Username: props.user ,access_token: oidcUser.access_token,};
      const groups = await listGroupsForUser(params)
      if (groups.length > 0) {
        let groupList = groups.map(a => a.GroupName);
        Alert.fire({
          text: 'Groups for ' + props.user + ": " + JSON.stringify(groupList),
          icon: 'success',
        })
      }
      else {
        Alert.fire({
          text: 'This user does not belong to any groups',
          icon: 'info'
        })
      }
    } catch (error) {
      Alert.fire({ text: error, icon: 'error' })
    }
  }
  const onDisableMFA = () => {
    const params = { UserPoolId: props.selectedUserPool.Id, Username: props.user ,access_token: oidcUser.access_token,};
    disableMFAForUser(params).then(result=>{
      Alert.fire({
        text: 'Disabled MFA for ' + props.user ,
        icon: 'success',
      });
    }).catch((error)=>{
      Alert.fire({ text: error, icon: 'error' })
    });
  }
  const onDeleteUser = async () => {
    let params = {
      "Username": props.user,
      "UserPoolId": props.selectedUserPool.Id,
      access_token: oidcUser.access_token,

    }

    Alert.fire({
      title: 'Delete ' + params.Username + '?',
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          deleteUser(params,{access_token: oidcUser.access_token,})

          // Alert.fire({
          //   text: 'User has been deleted',
          //   icon: 'success'
          // })
          //    console.log(data);          // successful response 
          props.delete(props.user);

        }
        catch (error) {
          Alert.fire({ text: error, icon: 'error' })
        }
      }
      else {
        // delete canceled
      }
    })
  }

  return (
    <>
      <Button variant="primary" onClick={onCreateUser} disabled={!props.selectedUserPool}>Create User</Button>{' '}
      <Button variant="primary" onClick={onResetUser} disabled={!props.user}>Reset User</Button>{' '}
      <Button variant="primary" onClick={onDeleteUser} disabled={!props.user}>Delete User</Button>{' '}
      <Button variant="primary" onClick={onAddGroup} disabled={!props.user}>Add to Group</Button>{' '}
      <Button variant="primary" onClick={onEditRegion} disabled={!props.user}>Modify Region</Button>{' '}
      <Button variant="primary" onClick={onRemoveGroup} disabled={!props.user}>Remove from Group</Button>{' '}
      <Button variant="primary" onClick={onShowGroups} disabled={!props.user}>Show Groups</Button>{' '}
      <Button variant="primary" onClick={onDisableMFA} disabled={!props.user}>Disable MFA</Button>{' '}

      {/* <Button variant="primary" onClick={onTestButton} disabled={!props.selectedUser}>Test</Button>{' '} */}
    </>
  )
}

export default Actions;
