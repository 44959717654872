import React, { useState, useEffect ,useCallback } from 'react';
import Button from "react-bootstrap/Button";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Alert from 'sweetalert2'
import { scanUserPools, putUserPool } from '../Services/UserPoolService'
import { useReactOidc } from "@axa-fr/react-oidc-context";


function UserPoolGrid(props) {
    const { oidcUser } = useReactOidc();
    const getUserPools = useCallback(() =>  {
        scanUserPools({access_token: oidcUser.access_token,}).then((arrayOfData)=>{
            setUserPoolList(arrayOfData);
        }).catch(error=>{
            console.log(error);
            Alert.fire({ text: 'Unable to scan the table. Error JSON:' + JSON.stringify(error, null, 2), icon: 'error' })

        });
    },[oidcUser]);
    useEffect(() => {
        setUserPoolList([]);
        getUserPools();
    }, [getUserPools]);

    const [userPoolList, setUserPoolList] = useState([]);
    const columns = [
        { dataField: 'Id', text: 'Id' },
        { dataField: 'Name', text: 'Name' },
        { dataField: 'Description', text: 'Description' },
        { dataField: 'NotificationsApp', text: 'Notifications App Name' },
        { dataField: 'DefaultMFA', text: 'Default MFA Pref' },
    ];



    const onAddNewUserPoolEntry = async () => {
        const { value: formValues } = await Alert.fire({
            title: 'Add a new User Pool',
            html:
                '<div class="form-group >' +
                '<label for="poolId">Id</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolId" placeholder="us-east-1_xxxxxxxxx"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="poolName">Name</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolName"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="poolDescription">Description</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolDescription"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="poolAwsAccountId">AWS Account Id</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolAwsAccountId"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="region">Region</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="region" placeholder="us-east-1 or us-east-2"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="poolIdentityPoolId">IdentityPoolId</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolIdentityPoolId"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="poolIdentityPoolAuthRole">IdentityPoolAuthRole</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolIdentityPoolAuthRole"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="poolIdentityPoolUnAuthRole">IdentityPoolUnAuthRole</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolIdentityPoolUnAuthRole"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="poolUserPoolClientId">UserPoolClientId</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolUserPoolClientId"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="poolUserPoolSecretKey">UserPoolSecretKey</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="poolUserPoolSecretKey"/></div>' +
                '</div>' +

                '<div class="form-group >' +
                '<label for="notificationApp">Notifications App Name</label>' +
                '<div class="col-sm"><input type="text" class="form-control" id="notificationApp"/></div>' +
                '</div>',
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            preConfirm: () => {
                return [
                    document.getElementById('poolId').value,
                    document.getElementById('poolName').value,
                    document.getElementById('poolDescription').value,
                    document.getElementById('poolAwsAccountId').value,
                    document.getElementById('region').value,
                    document.getElementById('poolIdentityPoolId').value,
                    document.getElementById('poolIdentityPoolAuthRole').value,
                    document.getElementById('poolIdentityPoolUnAuthRole').value,
                    document.getElementById('poolUserPoolClientId').value,
                    document.getElementById('poolUserPoolSecretKey').value,
                    document.getElementById('notificationApp').value
                ]
            }
        })

        if (formValues) {
            addUserPool(formValues).then((result) => {
                Alert.fire({
                    text: 'User Pool entry created successfully: ' + formValues[0],
                    icon: 'success'
                }).then(()=>{
                    getUserPools();
                })
            });
        }
    }

    const addUserPool = async (data) => {
        const input = {

            "UserPoolId" : data[0], //NOTE THIS IS ALSO MAPPED TO THE UNIQUE ID
            "Name" : data[1],
            "Description" : data[2],
            "AwsAccountId" : data[3],
            "RegionSysName" : data[4],

            "IdentityPoolId" : data[5],
            "IdentityPoolAuthRole" : data[6],
            "IdentityPoolUnAuthRole" : data[7],
            
            "UserPoolClientId" : data[8],
            "UserPoolSecretKey" : data[9],
            "NotificationsApp" : data[10],
        };

        try {
            const data = await putUserPool(input,{access_token: oidcUser.access_token,});
            if(data){
                return data;
            }
        } catch (error) {
            Alert.fire({ text: 'Unable to add item. Error JSON: ' + JSON.stringify(error, null, 2), icon: 'error' })
        }
    }

    const selectRow = {
        hideSelectColumn: true,
        clickToSelect: true,
        mode: 'radio',
        bgColor: '#6c757d',
        onSelect: (row, isSelect, rowIndex, e) => {
            //  alert('selected: ' + row.Username + ", " + row.Email);
            // props.selectedUserPool(row.Id);
        }
    };

    return (
        <>
            <BootstrapTable
                keyField="Id"
                data={userPoolList}
                columns={columns}
                striped
                hover
                noDataIndication="no user pools defined"
                selectRow={selectRow}
                condensed
            />
            <Button variant="primary" onClick={onAddNewUserPoolEntry}>Add User Pool</Button>{' '}
        </>

    )

}

export default UserPoolGrid;
