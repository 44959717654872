import React, { useState } from "react";
import UserPoolList from '../components/UserPoolList';
import httpCommon from "../Services/httpService";
import {useReactOidc} from "@axa-fr/react-oidc-context";
import BannerDetails from "../components/BannerDetails";
import BannerSelect from "../components/BannerSelect";
import imgLoading from "../assets/loading.svg";

function Banner() { 
  const [selectedUserPoolId, setSelectedUserPoolId] = useState('');
  const [isUserPoolListLoaded, setIsUserPoolListLoaded] = useState(true);
  const [bannerDetails, setBannerDetails] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { oidcUser } = useReactOidc();

  const onUserPoolSelected = async (pool) => {
    setIsLoading(true);
    setSelectedUserPoolId(pool.Id);
    console.log('User Pool selected:  ' + pool.Id);
    fetchBannerDetails(pool.Id).then(result => {
    }).catch(err => {
      setErrorMessage(err);
    });
  }

  const onUserPoolLoaded = (isloaded) =>{
    console.log('User Pool List Loaded');
    setIsUserPoolListLoaded(isloaded);
    setIsLoading(false);
  }

  const fetchBannerDetails = async (poolId) => {
    setIsLoading(true);
    const response = await httpCommon(oidcUser.access_token).get(`/pool/banner?userpoolid=${poolId}`);
    const bdetails = await response.data;
    console.log('Banner configuration for selected Pool: ', response.data);
    setBannerDetails(bdetails);
    setIsLoading(false);
  }

  // const onChildChange = (poolId) => {
  //   //TODO change so that the renders of siblings are triggered in BannerSelect
  //   // fetchBannerDetails(poolId).then(result => {
  //   // }).catch(err => {
  //   //   setErrorMessage(err);
  //   // });
  // }

  const containerStyle = {
    padding: "10px",
    maxWidth: "640px",
    margin: "0 auto"
  }

  const LoadingOverlay = ({ visible }) => {
    const loadingStyle = {
      display: visible ? "flex" : "none",
      position: "absolute",
      zIndex: "100",
      width: "100%",
      height: "100vh",
      left: "0",
      top: "0",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, 0.6)",
      backdropFilter: "blur(3px)",
    }
    return (
        <div style={loadingStyle}>
          <img src={imgLoading} width="64px" height="64px" />
          <div>Loading, please wait...</div>
        </div>
    )
  }

  return (
      <>
        <LoadingOverlay visible={isLoading}/>
        <div style={containerStyle}>
          <UserPoolList userPool={onUserPoolSelected} isUserPoolListLoaded={onUserPoolLoaded} />
          {
            bannerDetails !== '' ?
                //<BannerDetails bannerDetails={bannerDetails} />
                // <BannerSelect bannerDetails={bannerDetails} poolId={selectedUserPoolId} onChange={onChildChange}/>
                <BannerSelect bannerDetails={bannerDetails} poolId={selectedUserPoolId}/>
                : <></>
          }
          {
            errorMessage !== '' ?
                <div className="alert alert-danger" role="alert">
                  An error occurred while attempting to load the banner configuration for the selected User Pool:
                  <p/>
                  {errorMessage}
                </div>
                :
                <></>
          }
        </div>
      </>
  );
}

export default Banner;