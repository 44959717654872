function Dashboard() {

  const notFoundStyle = {
    padding: 10
  };


  return (
    <>
      <p style={notFoundStyle}>Welcome!  Please login to continue.</p>
    </>
  );
}

export default Dashboard;